import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <ul className="icons">
                    <li><a href="https://www.facebook.com/Sumlook-Production-%E6%A3%AE%E7%B6%A0%E5%89%B5%E6%84%8F%E5%9D%8A-Fan-Club-111137882618/" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="mailto:info@sumlook.com" className="icon alt fa-envelope"><span className="label">Email</span></a></li>
                    {/* <li><a href="#" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="#" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                    <li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li> */}
                </ul>
                <ul className="copyright">
                    <li>&copy; Sumlook Production</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                </ul> 
            </section>
        )
    }
}

export default Footer
