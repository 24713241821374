import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = (props) => (
  <Layout noHeader="true">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    <h1>{props.location.pathname}</h1>
    <ins class="adsbygoogle"
     style={{display:"block"}}
     data-ad-client="ca-pub-1613210695018765"
     data-ad-slot="2232944730"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    <script>
    {` (adsbygoogle = window.adsbygoogle || []).push({}); `}   
    </script>
    <p>NOT FOUND!! You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
